import React from "react";

import takeAwayImage from "../images/takeaway.png";
import diyImage from "../images/diy.png";
import estatePlusImage from "../images/estateplus.png";
import footballImage from "../images/football.png";
import snookerImage from "../images/snooker.png";
import emailImage from "../images/email.png";

import { Button, Col, Form, Row } from "react-bootstrap";

export const EstatePlus: React.FC = (): JSX.Element => {
  const estatePlus = (): JSX.Element => {
    return (
      <div className="row">
        <div className="col-md-8 mb-5">
          <h2>Estate Plus</h2>
          <hr />
          <br />
          <div>
            This is fully manage and main software for estate agent, taking care
            of your accounts and daily activity, and ease the pain of
            advertising on portal.
            <br />
            <br />
            <h2>Key Features</h2>
            - Easy to use web-based and letting software
            <br />- Scalable to grow with your business
            <br />- Uploads to property portals
            <br />- Full support is given
            <br />- Data security and backups
            <br />- No setup costs
            <br />- Rapid setup time
            <br />- Website hosting (optional)
            <br />- Easy to use interface
            <br />- Handy customisable dashboard with access to all the
            important information at a glance
            <br />- estate plus Smart Search: a system-wide global search that
            allows to search any kind of information stored in the system.
            <br />- Reminders for new messages and appointments
            <br />- A very user-friendly calendar to manage all appointments,
            viewings, valuations, reminders and meetings
            <br />- Easy to search for property and with one click access to
            property list
            <br />- Three level security for accessing the software; secure
            client key, username and password
            <br />- Activity monitor; tracks all the activities of the software
            so you can have a bird's eye view of what's happening in your
            company
            <br />- Create multiple user groups and control what you want them
            to access in the system
            <br />- Daily backups
            <br />
            <br />
            <h2>Price</h2> <br /> £1.49 per day (minimum contract six months)
            <br />
            <br />
          </div>
        </div>
        <div className="col-md-4 mb-5">
          <img src={estatePlusImage} className="card-img-top" />
        </div>
      </div>
    );
  };

  return <>{estatePlus()}</>;
};
