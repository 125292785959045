import axios, { AxiosResponse } from "axios";
import { IContact } from "../types/IContact";

export const API = axios.create({
  baseURL: "https://api.aleaf.co.uk/",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: () => true,
});

export const contactUsRequest = (
  newMessage: IContact
): Promise<boolean | void> => {
  return API.post("NewContact", newMessage)
    .then((response: AxiosResponse<boolean>) => {
      return response.data;
    })
    .catch(function (error: any) {
      console.log("error from creating contact via  api :  " + error);
      return;
    });
};
