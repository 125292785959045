import React from "react";

import takeAwayImage from "../images/takeaway.png";
import diyImage from "../images/diy.png";
import estatePlusImage from "../images/estateplus.png";
import footballImage from "../images/football.png";
import snookerImage from "../images/snooker.png";
import emailImage from "../images/email.png";

import { Button, Col, Form, Row } from "react-bootstrap";

export const Football: React.FC = (): JSX.Element => {
  const football = (): JSX.Element => {
    return (
      <div className="row">
        <div className="col-md-8 mb-5">
          <h2>Football Pitch Manager</h2>
          <hr />
          <br />
          <div>
            Manage booking for football pitch
            <br />
            <br />
            <h2>Key Features</h2>
            <br />- Slick and Simple Easy to Use
            <br />- Support Block Booking
            <br />- Customer database with history of past and future
            <br />- Payment processing feature
            <br />- Booking SMS message
            <br />- Complete audit trail of booking and cancelations
            <br />- Reports and Exports for accountancy
            <br />- Many many more..
            <br />
            <br />
            <h2>Price</h2> 99p per day (minimum contract six months)
            <br />
            <br />
          </div>
        </div>
        <div className="col-md-4 mb-5">
          <img src={footballImage} className="card-img-top" />
        </div>
      </div>
    );
  };

  return <>{football()}</>;
};
