import React from "react";
import "bootstrap/dist/css/bootstrap.css";

import { Manager } from "./pages/manager/Manager";

function App() {
  return (
    <div>
      <Manager />
    </div>
  );
}

export default App;
