import React from "react";

import takeAwayImage from "../images/takeaway.png";
import diyImage from "../images/diy.png";
import estatePlusImage from "../images/estateplus.png";
import footballImage from "../images/football.png";
import snookerImage from "../images/snooker.png";
import emailImage from "../images/email.png";

import { Button, Col, Form, Row } from "react-bootstrap";

export const Snooker: React.FC = (): JSX.Element => {
  const snooker = (): JSX.Element => {
    return (
      <div className="row">
        <div className="col-md-8 mb-5">
          <h2>Snooker - EPOS</h2>
          <hr />
          <div>
            This software is tailed as a snooker manage it gives quick results
            on tables, rooms and food
            <br />
            <br />
            <h2>Key Features</h2>
            <br />
            *** This software is resilient to power failure. it will continue to
            run where it left off assistance
            <br />
            *** Multiple terminals
            <br />
            <br />
            <h2>Price</h2> <br /> 99p per day (minimum contract six months)
            <br />
            <br />
            <h2>Key Features</h2>
            <br />- Unlimited number of tables
            <br />- Any number of categories
            <br />- Any number of items
            <br />- Prices for peek and off peek
            <br />- Advance booking (Alert is one hour before table is due to
            start)
            <br />- Queue for table (including waiting times)
            <br />- Full receipt history/storage/reprint
            <br />- Extract receipts for analysis
            <br />- Reports
            <br />
            -daily, monthly, yearly, date time range
            <br />- Remote assistance
            <br />
            <br />
            ** Optional for backup
            <br />
            ** Internet is required for backup to server and remote assistance
            <br />
          </div>
        </div>
        <div className="col-md-4 mb-5">
          <img src={snookerImage} className="card-img-top" />
        </div>
      </div>
    );
  };

  return <>{snooker()}</>;
};
