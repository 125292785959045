import React from "react";

import takeAwayImage from "../images/takeaway.png";
import diyImage from "../images/diy.png";
import estatePlusImage from "../images/estateplus.png";
import footballImage from "../images/football.png";
import snookerImage from "../images/snooker.png";
import emailImage from "../images/email.png";

import { Button, Col, Form, Row } from "react-bootstrap";

export const DIY: React.FC = (): JSX.Element => {
  const diy = (): JSX.Element => {
    return (
      <div className="row">
        <div className="col-md-8 mb-5">
          <h2>DIY - EPOS</h2>
          <hr />
          <div>
            This software can be tailed to many epos settings but more
            specifically towards hardware shops... Talk to us
            <br />
            <br />
            <h2>Ideal for</h2>
            <br />- ideal for diy shops
            <br />- warehouses
            <br />- online shopping
            <br />* Internet is required for backup to server and remote
            assistance
            <br />
            <br />
            <h2>Price</h2> <br /> 99p per day (minimum contract six months)
            <br />
            <br />
            <h2>Key Features</h2>
            <br />- Any number of categories
            <br />- Any number of items
            <br />- Item offer prices
            <br />- Item offer start and end
            <br />- BOGOF setup
            <br />- Full receipt history/storage/reprint
            <br />- Extract receipts for analysis
            <br />- Reports - daily, monthly, yearly, date time range
            <br />- Add receipt to client account
            <br />- Account alerts
            <br />- Optional for backup**
            <br />
          </div>
        </div>
        <div className="col-md-4 mb-5">
          <img src={diyImage} className="card-img-top" />
        </div>
      </div>
    );
  };

  return <div>{diy()}</div>;
};
