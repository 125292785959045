import React, { useState } from "react";

import emailImage from "../images/email.png";

import { Button, Col, Form, Modal } from "react-bootstrap";
import { contactUsRequest } from "../../services/Service";
import { IContact } from "../../types/IContact";

export const ContactUs: React.FC = (): JSX.Element => {
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");

  const [thankyouForm, setThankyouForm] = useState(false);
  const [errorInCommunication, setErrorInCommunication] = useState(false);

  const [nameValid, setNameValid] = useState(false);
  const [contactValid, setContactValid] = useState(false);
  const [messageValid, setMessageValid] = useState(false);

  function clear() {
    setName("");
    setContact("");
    setMessage("");
  }

  async function createContactRequest() {
    const newContact: IContact = {
      Id: Date.now().toString(),
      Name: name,
      telephone: contact,
      Message: message,
    };

    const result = await contactUsRequest(newContact);

    if (!result) {
      setErrorInCommunication(true);
      return;
    } else {
      clear();
      setThankyouForm(true);
    }
  }

  function createNewContact() {
    setNameValid(false);
    setContactValid(false);
    setMessageValid(false);

    if (name === "") {
      setNameValid(true);
      return;
    }
    if (contact === "") {
      setContactValid(true);
      return;
    }

    if (message === "") {
      setMessageValid(true);
      return;
    }

    createContactRequest();
  }

  const errorCommunication = (): JSX.Element => {
    return (
      <>
        <Modal
          show={errorInCommunication}
          onHide={() => setErrorInCommunication(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Error!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              There is an error communicating to the server. Please call us on
              07447 887206 <br />
              <br />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => setErrorInCommunication(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const thankYouContact = (): JSX.Element => {
    return (
      <>
        <Modal show={thankyouForm} onHide={() => setThankyouForm(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Thank you</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              Your information has been sent us. One of our advisers will
              contact you shortly. <br />
              <br />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setThankyouForm(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const contactUs = (): JSX.Element => {
    return (
      <div className="row">
        <div className="col-md-8 mb-5">
          <h2>Contact Us</h2>
          <hr />
          <div>
            <Form.Group>
              <Form.Label>Full Name</Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  required
                  value={name}
                  name="test"
                  isInvalid={nameValid}
                  onChange={(c) => setName(c.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group>
              <Form.Label>Your email/telephone</Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  value={contact}
                  isInvalid={contactValid}
                  onChange={(c) => setContact(c.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group>
              <Form.Label>Message</Form.Label>
              <Col sm="10">
                <Form.Control
                  as="textarea"
                  isInvalid={messageValid}
                  value={message}
                  onChange={(c) => setMessage(c.target.value)}
                />
              </Col>
            </Form.Group>
            <br />
            <Button variant="success" onClick={createNewContact}>
              Submit
            </Button>
          </div>
        </div>
        <div className="col-md-4 mb-5">
          <img src={emailImage} className="card-img-top" />
        </div>
      </div>
    );
  };

  return (
    <>
      <>{contactUs()}</>
      <>{thankYouContact()}</>
      <>{errorCommunication()}</>
    </>
  );
};
