import React from "react";

import takeAwayImage from "../images/takeaway.png";
import diyImage from "../images/diy.png";
import estatePlusImage from "../images/estateplus.png";
import footballImage from "../images/football.png";
import snookerImage from "../images/snooker.png";
import emailImage from "../images/email.png";

import { Button, Col, Form, Row } from "react-bootstrap";

export const Takeaway: React.FC = (): JSX.Element => {
  const takeAway = (): JSX.Element => {
    return (
      <div className="row">
        <div className="col-md-8 mb-5">
          <h2>Take Away - EPOS</h2>
          <hr />
          <div>
            This software can be tailed to many epos settings... Talk to us
            <br />
            <br />
            <h2>Some settings include</h2>
            <br />
            - Takeaway <br />- Restaurants <br />- Convenient store <br />-
            Shisha lounges <br />- Pubs <br />- Butchers <br />- And many other
            fast food oriented settings
            <br />
            <br />
            <h2>Price</h2> <br /> 99p per day (minimum contract six months)
            <br />
            <br />
            <h2>Key Features</h2>
            <br /> - Caller Id ready* (regardless of which screen you are on){" "}
            <br />- Any number of categories <br />- Any number of items <br />-
            Any number of item instruction <br />- logo configurable <br />-
            Full receipt history/storage/reprint <br />- Extract receipts for
            analysis <br />- Reports <br />- daily, monthly, yearly, date time
            range - Remote assistance** <br />- Ordered item instructions <br />
            - Customer receipt id is reset on application restart <br />- Eject
            cash drawer optional on epos order (see attached image) <br />- Sell
            by weight calculation (meat/fruit) <br />- Users with security
            profile. e.g. see order screen only <br />
            <br />* you will need a caller id active line. This service is
            usually provided by BT <br />
            ** Internet is required for backup to server and remote assistance
            <br />
          </div>
        </div>
        <div className="col-md-4 mb-5">
          <img src={takeAwayImage} className="card-img-top" />
        </div>
      </div>
    );
  };

  return <div>{takeAway()}</div>;
};
