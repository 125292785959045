import React, { useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";

import takeAwayImage from "../images/takeaway.png";
import diyImage from "../images/diy.png";
import estatePlusImage from "../images/estateplus.png";
import footballImage from "../images/football.png";
import snookerImage from "../images/snooker.png";
import mobileImage from "../images/mobile.png";
import { IContact } from "../../types/IContact";
import { contactUsRequest } from "../../services/Service";

export const Home: React.FC = (): JSX.Element => {
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [message, setMessage] = useState("");

  const [thankyouForm, setThankyouForm] = useState(false);
  const [errorInCommunication, setErrorInCommunication] = useState(false);

  const [nameValid, setNameValid] = useState(false);
  const [contactValid, setContactValid] = useState(false);
  const [messageValid, setMessageValid] = useState(false);

  function clear() {
    setName("");
    setContact("");
    setMessage("");
  }

  async function createContactRequest() {
    const newContact: IContact = {
      Id: Date.now().toString(),
      Name: name,
      telephone: contact,
      Message: message,
    };

    const result = await contactUsRequest(newContact);

    if (!result) {
      setErrorInCommunication(true);
      return;
    } else {
      clear();
      setThankyouForm(true);
    }
  }

  function createNewContact() {
    setNameValid(false);
    setContactValid(false);
    setMessageValid(false);

    if (name === "") {
      setNameValid(true);
      return;
    }
    if (contact === "") {
      setContactValid(true);
      return;
    }

    if (message === "") {
      setMessageValid(true);
      return;
    }

    createContactRequest();
  }

  const errorCommunication = (): JSX.Element => {
    return (
      <>
        <Modal
          show={errorInCommunication}
          onHide={() => setErrorInCommunication(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Error!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              There is an error communicating to the server. Please call us on
              07447 887206 <br />
              <br />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => setErrorInCommunication(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const thankYouContact = (): JSX.Element => {
    return (
      <>
        <Modal show={thankyouForm} onHide={() => setThankyouForm(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Thank you</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              Your information has been sent us. One of our advisers will
              contact you shortly. <br />
              <br />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setThankyouForm(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const home = (): JSX.Element => {
    return (
      <div className="row">
        <div className="col-md-8 mb-5">
          <h2>Solutions & Services</h2>
          <hr />
          <div>
            Small or large we are here to walk you through the process. we have
            an agile process that includes you part of the journey when creating
            the software hence all you IT needs under one leaf. <br />
            <br />
            <h4>Key areas discussed</h4>
            - Skills & Technology <br />- Support & Maintenance <br />- Managed
            Cloud Hosting
            <br />
            <br />
          </div>
          <div>
            <h4>Ready Products</h4>
            <br />
            <div className="row row-cols-1 row-cols-md-3 g-4">
              <div className="col">
                <div className="card h-100">
                  <img src={takeAwayImage} className="card-img-top" />
                  <div className="card-body">
                    <h5 className="card-title">Retail (EPOS)</h5>
                    <p className="card-text">
                      This software can be tailed to many epos settings.
                      <br />- Takeaway <br />- Resturants <br />- Convenient
                      store <br />- Shisha lounges <br />- Pubs <br />- Butchers{" "}
                      <br />- And many other fast food oriented settings
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img src={diyImage} className="card-img-top" />
                  <div className="card-body">
                    <h5 className="card-title">DIY (EPOS)</h5>
                    <p className="card-text">
                      This software can be tailed to many epos settings but more
                      specifically towards hardware shops.
                      <br />
                      <br />
                      setting it can be used in
                      <br />- ideal for diy shops
                      <br />- warehouses
                      <br />- online shopping
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img
                    src={estatePlusImage}
                    className="card-img-top"
                    alt="airplane"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Estate plus</h5>
                    <p className="card-text">
                      This is fully manage and main software for estate agent,
                      taking care of your accounts and daily activity, and ease
                      the pain of advertising on portal. <br />
                      <br />
                      Click on the image for more details
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img
                    src={snookerImage}
                    className="card-img-top"
                    alt="hireCars"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Snooker (EPOS)</h5>
                    <p className="card-text">
                      This software is tailed as a snooker manage it gives quick
                      results on tables, rooms and food
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img src={footballImage} className="card-img-top" />
                  <div className="card-body">
                    <h5 className="card-title">Football Pitch Manager</h5>
                    <p className="card-text">
                      designed with football pitch management
                      <br />
                      <br />
                      - Slick and Simple Easy to Use <br />- Support Block
                      Booking <br />- Customer database with history of past and
                      future <br />- Payment processing feature <br />- Booking
                      SMS message <br />- Complete audit trail of booking and
                      cancellations <br />- Reports and Exports for accountancy
                      <br />- Many many more..
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100">
                  <img src={mobileImage} className="card-img-top" />
                  <div className="card-body">
                    <h5 className="card-title">Mobile Shop (EPOS)</h5>
                    <p className="card-text">
                      designed with mobile phone shop.
                      <br />
                      <br />- Manage IMEI number
                      <br />- Search products across the store
                      <br />- Search for transaction
                      <br />- Transactions/Returns
                      <br />- Report faulty items
                      <br />- Runs on windows/linux/android/mac/ios
                      <br />- Manage accessories
                      <br />- Reports
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-5">
          <h2>Contact Us</h2>
          <hr />
          Book a demo or just talk to us and we will find a solution
          <br />
          <br />
          <div>
            <Form.Group>
              <Form.Label>Full Name</Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  required
                  value={name}
                  name="test"
                  isInvalid={nameValid}
                  onChange={(c) => setName(c.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group>
              <Form.Label>Your email/telephone</Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  value={contact}
                  isInvalid={contactValid}
                  onChange={(c) => setContact(c.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group>
              <Form.Label>Message</Form.Label>
              <Col sm="10">
                <Form.Control
                  as="textarea"
                  isInvalid={messageValid}
                  value={message}
                  onChange={(c) => setMessage(c.target.value)}
                />
              </Col>
            </Form.Group>
            <br />
            <Button variant="success" onClick={createNewContact}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <>{home()}</>
      <>{thankYouContact()}</>
      <>{errorCommunication()}</>
    </>
  );
};
