import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Button } from "react-bootstrap";
import { Home } from "../home/Home";
import { Takeaway } from "../takeAway/TakeAway";
import { DIY } from "../diy/DIY";
import { Snooker } from "../snooker/Snooker";
import { EstatePlus } from "../estatePlus/EstatePlus";
import { Football } from "../football/Football";
import { ContactUs } from "../contactUs/ContactUs";

export const Manager: React.FC = (): JSX.Element => {
  const [homeView, setHomeView] = useState(true);
  const [takeAwayView, setTakeAwayView] = useState(false);
  const [diyView, setDIYView] = useState(false);
  const [snookerView, setSnookerView] = useState(false);
  const [estatePlusView, setEstatePlusView] = useState(false);
  const [footballView, setFootballView] = useState(false);
  const [contactView, setContactView] = useState(false);

  const pages = (): JSX.Element => {
    return (
      <>
        <>{homeView ? <Home /> : <></>}</>
        <>{takeAwayView ? <Takeaway /> : <></>}</>
        <>{diyView ? <DIY /> : <></>}</>
        <>{snookerView ? <Snooker /> : <></>}</>
        <>{estatePlusView ? <EstatePlus /> : <></>}</>
        <>{footballView ? <Football /> : <></>}</>
        <>{contactView ? <ContactUs /> : <></>}</>
      </>
    );
  };

  function clearViews() {
    setHomeView(false);
    setTakeAwayView(false);
    setDIYView(false);
    setSnookerView(false);
    setEstatePlusView(false);
    setFootballView(false);
    setContactView(false);
  }
  function showContactView() {
    clearViews();
    setContactView(true);
  }
  function showFootballView() {
    clearViews();
    setFootballView(true);
  }
  function showSnookerView() {
    clearViews();
    setSnookerView(true);
  }
  function showTakeAwayView() {
    clearViews();
    setTakeAwayView(true);
  }
  function showHomeView() {
    clearViews();
    setHomeView(true);
  }
  function showDIYView() {
    clearViews();
    setDIYView(true);
  }
  function showEstatePlus() {
    clearViews();
    setEstatePlusView(true);
  }

  return (
    <body>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
        <div className="container">
          <Button
            variant="link"
            className="navbar-brand"
            onClick={showHomeView}
          >
            aleaf solutions
          </Button>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <Button
                  variant="link"
                  className="nav-link"
                  data-toggle="collapse"
                  data-target="#navbarResponsive"
                  aria-controls="navbarResponsive"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={showTakeAwayView}
                >
                  Takeaway
                </Button>
              </li>
              <li className="nav-item active">
                <Button
                  variant="link"
                  className="nav-link"
                  data-toggle="collapse"
                  data-target="#navbarResponsive"
                  aria-controls="navbarResponsive"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={showDIYView}
                >
                  DIY
                </Button>
              </li>
              <li className="nav-item active">
                <Button
                  variant="link"
                  className="nav-link"
                  data-toggle="collapse"
                  data-target="#navbarResponsive"
                  aria-controls="navbarResponsive"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={showSnookerView}
                >
                  Snooker
                </Button>
              </li>
              <li className="nav-item active">
                <Button
                  variant="link"
                  className="nav-link"
                  data-toggle="collapse"
                  data-target="#navbarResponsive"
                  aria-controls="navbarResponsive"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={showEstatePlus}
                >
                  Estate Plus
                </Button>
              </li>
              <li className="nav-item active">
                <Button
                  variant="link"
                  className="nav-link"
                  data-toggle="collapse"
                  data-target="#navbarResponsive"
                  aria-controls="navbarResponsive"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={showFootballView}
                >
                  Football
                </Button>
              </li>
              <li className="nav-item active">
                <Button
                  variant="link"
                  className="nav-link"
                  data-toggle="collapse"
                  data-target="#navbarResponsive"
                  aria-controls="navbarResponsive"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={showContactView}
                >
                  Contact Us
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <header className="bg-success py-5 mb-5">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-lg-12">
              <h1 className="display-4 text-white mt-5 mb-2">07447 887206</h1>
              <p className="lead mb-5 text-white-50">
                All your software needs under on leaf
              </p>
            </div>
          </div>
        </div>
      </header>
      <div className="container">{pages()}</div>

      <footer className="py-5 bg-dark">
        <div className="container">
          <p className="m-0 text-center text-white">
            07447 887206
            <br />
            info@aleaf.co.uk
            <br />
            Greathorton Road, Bradford. BD7 1DP
            <br />
          </p>
        </div>
      </footer>
    </body>
  );
};
